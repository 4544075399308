<template>
  <div>
    <!-- 我们的服务 -->
    <div id="/" class="_pc-pad-y-60 _mb-pad-y-70 _pc-pad-x-260 _mb-pad-x-30 wow fadeInUp">
      <div class="_flex-row-bet _pc-mar-bottom-45 _mb-mar-bottom-20">

        <div class="_flex-row">
          <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 _mb-mar-right-25"
            style=" line-height: 0.9;color:var(--theme-color);">我们的服务</div>
          <div class="_pc-font-21 _b _mb-font-26 _grey-99" style="align-self: flex-end">SERVICE</div>
        </div>
      </div>

      <div class="_flex-row-bet _mb-flex-col">
        <img class="_pc-w-730 _pc-mar-right-85 _mb-mar-right-0 _mb-order-1 _mb-w-max" :src="serve_list.image_input"
          alt="" />

        <div class="_flex-1 _html-img _flex-col-bet _mb-mar-bottom-60">

          <div>
            <div class="title-color _pc-font-24 _pc-mar-bottom-20 _mb-mar-bottom-30 _mb-font-28 _b">寄货服务</div>
            <div class="_pc-font-18 _black _mb-font-24 _mb-mar-bottom-30" style="line-height: 2"
              v-html="serve_list.content"></div>
          </div>

          <div>
            <div class="title-color _pc-font-24 _pc-mar-bottom-25 _mb-mar-bottom-30 _mb-font-28 _b">打包方式</div>
            <div class="_flex-row-bet-mid">

              <div class="_pc-font-16 _mb-font-24 _flex-col-mid">
                <img class="_pc-w-55 _mb-w-80 _pc-mar-bottom-10 _mb-mar-bottom-10" src="@/assets/image/pack-01.png"
                  alt="" />
                <div>验货收货</div>
              </div>

              <img class="_pc-w-25 _mb-w-30" src="@/assets/image/right-ico-01.png" alt="" />
              <div class="_pc-font-16 _mb-font-24 _flex-col-mid">
                <img class="_pc-w-55 _mb-w-80 _pc-mar-bottom-10 _mb-mar-bottom-10" src="@/assets/image/pack-02.png"
                  alt="" />
                <div>合并包裹</div>
              </div>

              <img class="_pc-w-25 _mb-w-30" src="@/assets/image/right-ico-01.png" alt="" />
              <div class="_pc-font-16 _mb-font-24 _flex-col-mid">
                <img class="_pc-w-55 _mb-w-80 _pc-mar-bottom-10 _mb-mar-bottom-10" src="@/assets/image/pack-03.png"
                  alt="" />
                <div>包裹拍照</div>
              </div>

              <img class="_pc-w-25 _mb-w-30" src="@/assets/image/right-ico-01.png" alt="" />
              <div class="_pc-font-16 _mb-font-24 _flex-col-mid">
                <img class="_pc-w-55 _mb-w-80 _pc-mar-bottom-10 _mb-mar-bottom-10" src="@/assets/image/pack-04.png"
                  alt="" />
                <div>包裹重量图</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 关于集运 -->
    <div class="_mb-display-none">
      <div class="_pc-pad-top-60 _mb-pad-top-70 grey-bg _pc-pad-x-260 _mb-pad-x-30" style="display: grid;grid-template-columns: 1fr 39.0625vw;">
        <div class="_pc-mar-right-65 _pc-pad-bottom-25 _flex-col-mid wow fadeInLeft">
          <div class="_flex-row _pc-mar-bottom-40 _mb-mar-bottom-20" style="align-self: flex-start">
            <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 _mb-mar-right-25"
              style=" line-height: 0.9;color:var(--theme-color)">
              {{ about_list.title }}
            </div>
            <div class="_pc-font-21 _b _mb-font-26 _grey-99" style="align-self: flex-end">ABOUT</div>
          </div>

          <div class="_pc-font-18 _mb-font-24 _pc-mar-bottom-50 _mb-mar-bottom-25 _black " style="line-height: 2"
            v-html="about_list.content"></div>
          <router-link to="/about" style="align-self: flex-start">
            <div class="
                _btn-p-hover
                _pc-pad-x-30 _pc-pad-y-15 
                _flex-row-mid
                _pc-font-16
                _mb-display-none
              ">
              关于我们
              <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10"></i>
            </div>
          </router-link>
          <router-link to="/about" style="align-self: flex-start">
            <div class="
                _btn-p-hover
                _mb-w-425
                _mb-h-75
                _flex-row-center-mid
                _mb-font-24
                _pc-display-none
              ">
              关于我们
              <i class="jy-iconfont bzjygw-a-ziyuan8 _pc-mar-left-10"></i>
            </div>
          </router-link>
        </div>

        <img class="_pc-w-750 wow fadeInRight _mb-w-max _mb-mar-top-60" :src="about_list.image_input" alt="" />
      </div>
    </div>
    

    <div class="_pc-pad-top-60 _mb-pad-top-70 _flex-row-bet _pc-display-none _mb-display-block grey-bg _pc-pad-x-260 _mb-pad-x-30">
      <div class="_pc-mar-right-65 _pc-pad-bottom-25 _flex-col-mid wow fadeInLeft">
        <div class="_flex-row _pc-mar-bottom-40 _mb-mar-bottom-20" style="align-self: flex-start">
          <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 _mb-mar-right-25"
            style=" line-height: 0.9;color:var(--theme-color)">
            {{ about_list.title }}
          </div>
          <div class="_pc-font-21 _b _mb-font-26 _grey-99" style="align-self: flex-end">ABOUT</div>
        </div>

        <div class="_pc-font-18 _mb-font-24 _pc-mar-bottom-50 _mb-mar-bottom-25 _black " style="line-height: 2"
          v-html="about_list.content"></div>
        <router-link to="/about" style="align-self: flex-start">
          <div class="
              _btn-p-hover
              _pc-pad-x-30 _pc-pad-y-15 
              _flex-row-mid
              _pc-font-16
              _mb-display-none
            ">
            关于我们
            <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10"></i>
          </div>
        </router-link>
        <router-link to="/about" style="align-self: flex-start">
          <div class="
              _btn-p-hover
              _mb-w-425
              _mb-h-75
              _flex-row-center-mid
              _mb-font-24
              _pc-display-none
            ">
            关于我们
            <i class="jy-iconfont bzjygw-a-ziyuan8 _pc-mar-left-10"></i>
          </div>
        </router-link>
      </div>

      <img class="_pc-w-750 wow fadeInRight _mb-w-max _mb-mar-top-60" :src="about_list.image_input" alt="" />
    </div>

    <!-- 路线介绍 -->

    <div class="_pc-pad-y-60 _mb-pad-y-60  _pc-pad-x-260 _mb-pad-x-30" v-if="route_list.length != 0">
      <div class="_flex-row-bet _pc-mar-bottom-45 _mb-mar-bottom-20 wow fadeInLeft">
        <div class="_flex-row">
          <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 _mb-mar-right-25 "
            style="color: var(--theme-color); line-height: 0.9">
            路线介绍
          </div>
          <div class="_pc-font-21 _b _mb-font-26 _grey-99" style="align-self: flex-end">ROUTE</div>
        </div>
      </div>



      <div class="_flex-row-bet _mb-display-block _flex-wrap wow fadeInRight">
        <div class="
            _pc-w-670
            _pc-pad-x-30
            _pc-pad-y-45
            _pc-mar-bottom-35
            _flex-row
            _pos-rel
            _mb-display-none
          " v-for="(item, index) in route_list" :key="index"
          v-show="show_all ? index <= route_list.length : index <= 3">
          <img class="_pc-w-235 _pc-mar-right-35 _pos-rel _z-index-1" style="height: 12.23958vw; object-fit: cover;" :src="item.logo" alt="" />

          <div class="_flex-col-bet-mid _pos-rel _z-index-1 _mb-pad-top-15 _flex-1">
            <div class="_w-max">
              <div class="_b _pc-font-24 _mb-mar-bottom-10 _mb-pad-top-10"
                style="align-self: flex-start;">
                {{ item.name }}
              </div>
              <div class="_pc-font-18 _pc-mar-top-15 _mb-mar-bottom-15 _black _line-4" style="line-height: 2">
                {{ item.length_limit }}{{ item.style }}{{ item.articles_limit }}
              </div>
            </div>
            
            <router-link to="/estimated_price" class="_pc-mar-top-20" style="align-self: flex-start">
              <div class="
                  _btn-p-hover
                  _pc-pad-x-30 _pc-pad-y-15 _mb-pad-y-5
                  _flex-row-mid
                  _pc-font-16
                " style="align-self: flex-start">
                预估运费
                <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10"></i>
              </div>
            </router-link>
          </div>

          <img class="_pos-abs-top _left _right _bottom _w-max _h-max" style="object-fit: contain;" src="@/assets/image/index-route-bg.jpg" alt="" />
        </div>


        <div class="
            _pos-rel
            _pc-display-none
            _flex-row
            _mb-pad-x-30
            _mb-pad-y-30
            _mb-mar-bottom-30
          " v-for="(item, index) in route_list" :key="item.id"
          v-show="show_all ? index <= route_list.length : index <= 1">
          <img class="_mb-w-235 _mb-h-335 _mb-mar-right-35 _pos-rel _z-index-1" style="object-fit: contain;" :src="item.logo" alt="" />

          <div class="_flex-col-bet-mid _pos-rel _z-index-1 _flex-1">
            <div class=" _mb-font-40 _mb-w-365 _line-1" style="align-self: flex-start;color:var(--theme-color)">
              {{ item.name }}
            </div>
            <div class="_mb-font-22  _black _line-4 _mb-mar-y-25" style="line-height: 1.5">
              {{ item.length_limit }}{{ item.style }}{{ item.articles_limit }}
            </div>
            <router-link to="/estimated_price"
              class="_btn-p-hover _mb-font-24 _mb-w-210 _mb-h-60 _text-center _flex-row-center-mid"
              style="cursor: pointer;align-self: flex-start">
              预估运费<div class="jy-iconfont bzjygw-a-ziyuan8 _mb-mar-left-10"></div>
            </router-link>
          </div>

          <img class="_pos-abs-left _w-max _h-max _top" style="object-fit: contain;" src="@/assets/image/index-route-bg.jpg" alt="" />

        </div>


      </div>

      <div class="_flex-row-center-mid _mb-display-none">
        <div class="
            _btn-p-hover
            _pc-pad-x-30 _pc-pad-y-15 _mb-pad-y-5
            _flex-row-mid
            _pc-font-16
          " style="
            cursor: pointer;
            align-self: flex-start;
          " v-if="!show_all" @click="show_all = !show_all">
          展示更多
        </div>

        <div class="
            _btn-p-hover
            _pc-pad-x-30 _pc-pad-y-15 _mb-pad-y-5
            _flex-row-mid
            title-color
            _pc-font-16
          " style="
            cursor: pointer;
            align-self: flex-start;
          " v-if="show_all" @click="show_all = !show_all">
          折叠
        </div>
      </div>
      <div class="_flex-row-center-mid _pc-display-none">
        <div class=" _mb-font-22 _mb-w-180 _mb-pad-y-15 _text-center _btn-p-hover"
          style="cursor: pointer;" @click="show_all = !show_all">
          {{ show_all ? '折叠' : '展示更多' }}
        </div>
      </div>

    </div>

    <!-- 下单流程 -->

    <div class="
        _pc-pad-y-60 _pc-pad-x-260 _mb-pad-y-60 _mb-pad-x-0
        process-bg
        background-max
        wow
        fadeInDown
      ">
      <div class="_flex-row-bet _pc-mar-bottom-45 _mb-pad-x-30 _mb-mar-bottom-5 wow fadeInLeft">
        <div class="_flex-row _mb-mar-bottom-20">
          <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 _mb-mar-right-25"
            style="color: var(--theme-color); line-height: 0.9">
            下单流程
          </div>
          <div class="_pc-font-21 _b _mb-font-26 _white" style="align-self: flex-end">
            PROCESS
          </div>
        </div>
      </div>

      <div class="_flex-row-bet-mid _pc-font-20 _mb-font-28 wow fadeInRight _mb-grid-image _mb-mar-top-70">
        <div class="_flex-col-mid _white index-pro " v-for="(item, index) in list" :key="index">
          <img class="_pc-w-50 _mb-w-65 _z-index-1" :src="item.image_input[0]" alt="" />

          <div class="_flex-row-center-mid _pos-rel _w-max">
            <div class="_border-top _pos-abs-right _mb-w-240" :class="index == list.length - 2? '_pc-w-240' : '_pc-w-270'" style="border: 1px solid rgba(255,255,255,0.4);opacity:40%">
            </div>

            <img class="_pc-pad-y-20 _mb-pad-y-20 _pc-w-30 _mb-w-45" src="@/assets/image/pro-ico.png" alt="" />


          </div>

          <div class="_white">{{ item.title }}</div>
        </div>
      </div>
    </div>

    <!-- 客户好评 -->

    <div class="_pc-pad-y-60 _pc-pad-x-260 _mb-pad-y-60 _mb-pad-x-30" v-if="comm_list.length != 0">
      <div class="_flex-row-bet _pc-mar-bottom-45 _mb-mar-bottom-25 wow fadeInLeft">
        <div class="_flex-row">
          <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 _mb-mar-right-25"
            style="color: var(--theme-color); line-height: 0.9">
            客户好评
          </div>
          <div class="_pc-font-21 _b _mb-font-26 _grey-99" style="align-self: flex-end">EVALUATE</div>
        </div>
      </div>

      <div class="evaluate-box wow fadeInRight">
        <div class="_flex-row _mb-mar-bottom-50" v-for="(item, index) in comm_list" :key="index">
          <img class="_border-circle _pc-mar-right-15 _pc-w-65 _mb-w-90 _mb-mar-right-20 evaluate-imgs"
            :src="item.headimgurl" />

          <div class="_pc-font-20 _black _w-max" style="overflow: hidden">
            <div class="_pc-mar-bottom-10 _mb-mar-bottom-20 _pc-font-20 _mb-font-28">{{ item.nickname }}</div>
            <div class="grey-bg _pc-pad-x-20 _pc-pad-y-15 _pc-font-16 _mb-font-24 _h-max _mb-pad-y-30 _mb-pad-x-30"
              style="border-radius: 8px">
              <div>
                <div class="_pc-mar-bottom-10 _mb-mar-bottom-10  _line-1" style="line-height:1.5"
                  v-if="item.express_name">
                  路线：{{ item.area_express_name }}
                </div>
                <div class="_pc-mar-bottom-15 _mb-mar-bottom-15 _line-3 _min-h-80" style="line-height: 1.5;">
                  {{ item.content }}
                </div>
              </div>

              <div class="evaluate-img">
                <img class="_pc-w-95 _pc-h-95  _pc-mar-bottom-0 _mb-mar-bottom-0 _mb-h-160 _mb-w-160"
                  style="cursor: pointer;object-fit: cover;" v-for="(val, key) in item.images" :key="key" :src="val"
                  alt="" v-viewer />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="_w-max _flex-row-center-mid _pc-mar-top-40 _mb-display-none">
        <router-link to="/avaluation" class="
          _btn-p-hover
          _pc-pad-x-30 _pc-pad-y-15 _mb-pad-y-5 _mb-mar-top-5
          _flex-row-mid
          _pc-font-16
        " style="
          cursor: pointer;
          align-self: flex-start;
        ">
          查看更多
        </router-link>
      </div>


      <div class="_flex-row-center-mid _pc-display-none _mb-mar-top-30  _white">
        <router-link to="/avaluation"
          class="_btn-p-hover _mb-font-24 _mb-w-425 _mb-h-75 _text-center _flex-row-center-mid"
          style="cursor: pointer;">
          查看更多<div class="jy-iconfont bzjygw-a-ziyuan8 _mb-mar-left-10"></div>
        </router-link>
      </div>
    </div>

    <!-- 集运动态 -->

    <div class=" grey-bg wow fadeInDown _pc-pad-y-60 _pc-pad-x-260 _mb-pad-y-60 _mb-pad-x-30">
      <div class="_flex-row-bet _pc-mar-bottom-45 _mb-mar-bottom-25 wow fadeInLeft">
        <div class="_flex-row">
          <div class="_pc-font-42 _mb-font-52 _b _pc-mar-right-20 _mb-mar-right-25"
            style="color: var(--theme-color); line-height: 0.9">
            集运动态
          </div>
          <div class="_pc-font-21 _b _mb-font-26 _grey-99" style="align-self: flex-end">NEWS</div>
        </div>
      </div>

      <div class="_flex-row news-box _mb-display-block" v-if="new_list.length != 0">
        <router-link :to="{
          path: '/newList/detaile',
          query: {
            index: JSON.stringify(0),
            page: JSON.stringify(page),
            keyword: JSON.stringify(1),
          },
        }">
          <div class="_pc-mar-right-35 news-hover _pos-rel">
            <img class="_pc-w-610 _pc-h-300 _mb-w-max " style="vertical-align: bottom" :src="new_list[0].image_input[0]"
              alt="" />
            <div
              class="_mb-display-none _w-max _pc-pad-x-25 _pc-pad-top-30 _mb-pad-top-10 _pc-pad-bottom-25 _mb-pad-bottom-5 _flex-row-bet-mid">
              <div>
                <div class="_pc-font-24 _pc-mar-bottom-15 _mb-mar-bottom-5">
                  {{ new_list[0].title }}
                </div>
                <div class="subtitle _pc-font-16">{{ new_list[0].add_time }}</div>
              </div>
              <!-- <img class="_pc-w-50" src="@/assets/image/news-right.png" alt="" /> -->
              <div class="_pc-w-50 _border-circle btn _flex-row-center-mid" style="height: 2.60417vw;">
                <i class="jy-iconfont bzjygw-a-ziyuan7"></i>
              </div>
            </div>



            <div class="_pc-display-none _mb-pad-x-20 _mb-pad-y-20 _flex-row-bet-mid _left _bottom _pos-abs _w-max"
              style="background-color:rgba(0,0,0,0.6)">
              <div class="_white">
                <div class="_mb-font-28 _mb-mar-bottom-10">{{ new_list[0].title }}</div>
                <div class="_mb-font-22 _grey-99">{{ new_list[0].add_time }}</div>
              </div>

              <div class="_mb-w-60 _border-circle theme-color-bg theme-color-title _flex-row-center-mid" style="height: 8vw;">
                <i class="jy-iconfont bzjygw-a-ziyuan7" style="font-size: 1.33vw;"></i>
              </div>
            </div>
          </div>
        </router-link>

        <div class=" _w-max news-box _mb-pad-top-30">
          <router-link class="_w-max "
            :to="{ path: '/newList/detaile', query: { index: JSON.stringify(index), page: JSON.stringify(page), keyword: JSON.stringify(1), }, }"
            v-for="(item, index) in new_list" :key="index">
            <div class="_new-flex-row-mid news-list-hover _w-max _pos-rel _mb-mar-bottom-30" v-if="index != 0">
              <img class="_pc-mar-right-30 _pc-w-150 _pc-h-90  _pos-rel _z-index-1 _mb-w-260"
                :src="item.image_input[0]" />

              <div class="_w-max _flex-1 _mb-flex-col-bet _flex-col-bet _pc-pad-y-5">
                <div class="_flex-row-bet-mid _pc-mar-bottom-25 _mb-mar-bottom-0">
                  <div class="_pc-font-20 _mb-font-28 _b">{{ item.title }}</div>
                  <div class="subtitle _pc-font-14 _mb-display-none" style="align-self: flex-end">{{ item.add_time }}
                  </div>
                </div>
                <div class="subtitle _pc-font-16 _mb-font-22 _line-3" style="line-height: 1.5">{{ item.synopsis }}</div>

                <div class="subtitle _mb-font-22 _flex-row-mid _pc-display-none" style="line-height: 1.5">
                  <img class="_mb-w-20 _mb-mar-right-10" src="@/assets/image/icon-clock.png" alt="">
                  <div>{{ item.add_time }}</div>
                </div>
              </div>

            </div>
          </router-link>
        </div>
      </div>

      <div class="_w-max _flex-row-center-mid _pc-mar-top-45 _mb-display-none" v-if="new_list.length != 0">
        <router-link to="/newList" style="align-self: flex-start">
          <div class=" _btn-p-hover _pc-pad-x-30 _pc-pad-y-15 _mb-pad-y-5 _mb-mar-top-5 _flex-row-mid _pc-font-16"
            style="align-self: flex-start">
            查看更多
            <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10"></i>
          </div>
        </router-link>
      </div>
      <div class="_flex-row-center-mid _pc-display-none _mb-mar-top-30  _white">
        <router-link to="/newList"
          class="_btn-p-hover _mb-font-24 _mb-w-425 _mb-h-75 _text-center _flex-row-center-mid"
          style="cursor: pointer;">
          查看更多<div class="jy-iconfont bzjygw-a-ziyuan8 _mb-mar-left-10"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexPage",
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 5, //每页条数
        currentPage: 1, //页码
      },
      comm_list: [], //用户评价列表
      route_list: [], //路线列表
      new_list: [], //新闻列表
      list: [], //下单流程
      serve_list: {}, //我们的服务
      about_list: {}, //关于亚速通
      show_all: false,
    };
  },
  // metaInfo: {
  //   title: '嘟嘟集运 - 跨国集运专家 | 海外华人留学生集运服务',
  //   meta: [{
  //     name: 'Keywords',
  //     content: '嘟嘟集运, 包裹集运, 国货集运, 海外华人集运, 留学生集运服务, 淘宝双十一集运, 淘宝转运, 淘宝转寄海外, 淘宝集中转运, 集运服务, 中国到海外集运'
  //   },
  //   {
  //     name: 'Description',
  //     content: '嘟嘟集运，您的跨国集运专家。专为海外华人和留学生提供便捷、高效的集运服务。无论您身在何处，享受家乡商品的乐趣。轻松、快捷、安全，让您的海外生活更加丰富多彩。加入我们，探索更多集运优惠和服务。'
  //   }]
  // },

  beforeMount() {
    this.get_serve();
  },
  mounted() {
    this.get_comm();
    this.get_route();
    this.get_news();
    this.get_process();
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (flag == null) {
      setTimeout(() => {
        new this.$wow.WOW().init()
      }, 300)
    }
  },

  methods: {


    async get_serve() {
      //获取我们的服务/关于亚速通
      let that = this;
      await that.$axios
        .get(
          'site/get_site_newList?page=1&limit=5&cid=5',
          {}
        )
        .then((res) => {
          that.serve_list = res.data.data.all[1];
          that.about_list = res.data.data.all[0];
        });
    },

    async get_comm() {
      //获取评论
      await this.$axios
        .get(
          'site/get_site_comment?page=1&limit=0',
          {}
        )
        .then((res) => {
          res.data.data.featured.forEach((val, key) => {
            if (key <= 5) {
              this.comm_list.push(val);
            }
          });
        });
    },

    async get_route() {
      //获取路线
      let that = this;
      await that.$axios
        .get('express/list', {
          page: 2,
          limit: 11,
        })
        .then((res) => {
          res.data.data.list.forEach((val, key) => {
            if (key <= 99) {
              that.route_list.push(val);
            }
          });
        });
    },

    async get_news() {
      //获取新闻列表
      let that = this;
      await that.$axios
        .get(
          'site/get_site_newList?page=1&limit=5&cid=3',
          {}
        )
        .then((res) => {
          res.data.data.all.forEach((val) => {
            if (val.is_hot && that.new_list.length <= 4) {
              that.new_list.push(val);
            }
          });
        });
    },

    //下单流程
    async get_process() {
      let that = this;
      await that.$axios
        .get(
          'site/get_site_newList?cid=6&page=1&limit=15'
        )
        .then((res) => {
          that.list = res.data.data.all;
        });
    },
  },
};
</script>

<style scoped>
._html-img p {
  margin: 0;
  height: 100%;
}

._html-img img {
  height: 100%;
}

.title-color {
  color: var(--theme-color);
}

.grey-bg {
  background: #f7f7f7;
}

.process-bg {
  background-image: url("../assets/image/process-bg.png");
}

.background-max {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.index-pro ._pos-abs-right {
  left: 50%;
}

/* .index-pro:first-child ._pos-abs-right {
  left: 50%;
} */

.index-pro:last-child ._pos-abs-right {
  display: none;
}

.evaluate-box {
  display: grid;
  grid-template-columns: 22.39583vw 22.39583vw 22.39583vw;
  column-gap: 2.34375vw;
  row-gap: 2.34375vw;
}

.evaluate-imgs {
  height: 3.38542vw
}

.evaluate-img {
  display: grid;
  grid-template-columns: 4.94792vw 4.94792vw 4.94792vw;
  column-gap: 0.78125vw;
}

.news-hover {
  margin-top: 19px;
  color: #333;
}

.news-hover .subtitle{
  color: #999;
}

.news-hover .btn{
  background-color: var(--theme-color-bg);
  color: var(--theme-color-title);
}

.news-hover .btn .bzjygw-a-ziyuan7{
  font-size: 0.52vw;
}

.news-hover:hover {
  background-color: var(--theme-color-bg);
  color: var(--title-color);
}

.news-hover:hover .subtitle {
  color: var(--title-color);
}


.news-hover:hover .btn{
  color: var(--theme-color-bg);
  background-color: var(--theme-color-title);
}

.news-list-hover{
  margin-top: 19px;
  color: #333;
}

.news-list-hover .subtitle{
  color: #999;
}

.news-list-hover:hover{
  color: var(--theme-color);
}

.news-list-hover:hover .subtitle{
  color: var(--theme-color);
}

.news-box:first-child .news-hover {
  margin-top: 0;
}

/* .news-hover:hover div {
  color: var(--theme-color);
} */

._btn-hover:hover {
  opacity: 0.4;
}



._btn-p-hover:hover {
  border-color: var(--theme-color-bg);
  background: var(--theme-color-bg);
  color: var(--theme-color-title);
}

._btn-p-hover {
  color: var(--linear-btn-text);
  border: 1px solid var(--linear-btn-bg);
}

._btn-p-hover .bzjygw-a-ziyuan81{
  font-size: 0.36vw;
}

.right-ico-hover {
  display: block;
}

.right-ico-02 {
  display: none;
}

._btn-p-hover:hover .right-ico-02 {
  display: block;
}

._btn-p-hover:hover .right-ico-hover {
  display: none;
}

._min-h-80 {
  min-height: 80px;
}

._new-flex-row-mid {
  display: flex;
  /* align-items: center; */
}

@media screen and (max-width: 768px) {
  ._min-h-80 {
    min-height: 1.72518vh;
  }

  .news-box:nth-child(2) .news-hover:nth-child(2) {
    margin-top: 0;
  }

  .news-hover {
    margin-top: 0.45052vh;
  }

  .rotateY {
    transform: rotateY(180deg);
  }

  ._mb-grid-image {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 6.08828vh 0;
  }

  .evaluate-box {
    display: block;
  }

  .evaluate-imgs {
    height: 12vw;
  }

  .index-pro:nth-child(3n+1) ._pos-abs-right {
    left: 50%;
  }

  .index-pro:nth-child(2n+1) ._pos-abs-right {
    right: 50%;
  }

  .index-pro:nth-child(3) ._pos-abs-right {
    display: none;
  }

  .evaluate-img {
    display: grid;
    grid-template-columns: 21.33333vw 21.33333vw 21.33333vw;
    column-gap: 2.66667vw;
  }

  ._new-flex-row-mid {
    display: flex;
    align-items: unset;
  }

  ._btn-p-hover {
    color: var(--theme-color-title);
    border: 1px solid var(--theme-color-bg);
    background-color: var(--theme-color-bg);
  }

  ._btn-p-hover .bzjygw-a-ziyuan8{
    font-size: 1.07vw;
  }
}
</style>
